const tamil = {
  atlas: {
    groupA: {
      fontSize: 78,
      lineHeight: 84,
    },
    groupB: {
      fontSize: 96,
      lineHeight: 104,
    },
    groupD: {
      fontSize: 140,
      lineHeight: 148,
    },
  },
  elephant: {
    groupA: {
      fontSize: 60,
      lineHeight: 64,
    },
    groupB: {
      fontSize: 78,
      lineHeight: 84,
    },
    groupD: {
      fontSize: 116,
      lineHeight: 124,
    },
  },
  imperial: {
    groupA: {
      fontSize: 50,
      lineHeight: 54,
    },
    groupB: {
      fontSize: 52,
      lineHeight: 70,
    },
    groupD: {
      fontSize: 76,
      lineHeight: 104,
    },
  },
  royal: {
    groupA: {
      fontSize: 40,
      lineHeight: 44,
    },
    groupB: {
      fontSize: 40,
      lineHeight: 58,
    },
    groupD: {
      fontSize: 56,
      lineHeight: 80,
    },
  },
  foolscap: {
    groupA: {
      fontSize: 32,
      lineHeight: 36,
    },
    groupB: {
      fontSize: 30,
      lineHeight: 44,
    },
    groupD: {
      fontSize: 46,
      lineHeight: 68,
    },
  },
  canon: {
    groupA: {
      fontSize: 22,
      lineHeight: 30,
    },
    groupB: {
      fontSize: 26,
      lineHeight: 36,
    },
    groupD: {
      fontSize: 34,
      lineHeight: 46,
    },
  },
  trafalgar: {
    groupA: {
      fontSize: 16,
      lineHeight: 24,
    },
    groupB: {
      fontSize: 22,
      lineHeight: 30,
    },
    groupD: {
      fontSize: 28,
      lineHeight: 38,
    },
  },
  paragon: {
    groupA: {
      fontSize: 16,
      lineHeight: 24,
    },
    groupB: {
      fontSize: 18,
      lineHeight: 26,
    },
    groupD: {
      fontSize: 22,
      lineHeight: 30,
    },
  },
  doublePica: {
    groupA: {
      fontSize: 14,
      lineHeight: 22,
    },
    groupB: {
      fontSize: 16,
      lineHeight: 24,
    },
    groupD: {
      fontSize: 18,
      lineHeight: 26,
    },
  },
  greatPrimer: {
    groupA: {
      fontSize: 13,
      lineHeight: 22,
    },
    groupB: {
      fontSize: 14,
      lineHeight: 24,
    },
    groupD: {
      fontSize: 16,
      lineHeight: 24,
    },
  },
  bodyCopy: {
    groupA: {
      fontSize: 12,
      lineHeight: 20,
    },
    groupB: {
      fontSize: 14,
      lineHeight: 28,
    },
    groupD: {
      fontSize: 14,
      lineHeight: 28,
    },
  },
  pica: {
    groupA: {
      fontSize: 12,
      lineHeight: 20,
    },
    groupB: {
      fontSize: 14,
      lineHeight: 22,
    },
    groupD: {
      fontSize: 14,
      lineHeight: 22,
    },
  },
  longPrimer: {
    groupA: {
      fontSize: 11,
      lineHeight: 18,
    },
    groupB: {
      fontSize: 11,
      lineHeight: 22,
    },
    groupD: {
      fontSize: 12,
      lineHeight: 24,
    },
  },
  brevier: {
    groupA: {
      fontSize: 10,
      lineHeight: 15,
    },
    groupB: {
      fontSize: 10,
      lineHeight: 15,
    },
    groupD: {
      fontSize: 11,
      lineHeight: 15,
    },
  },
  minion: {
    groupA: {
      fontSize: 12,
      lineHeight: 16,
    },
    groupB: {
      fontSize: 12,
      lineHeight: 16,
    },
    groupD: {
      fontSize: 10,
      lineHeight: 14,
    },
  },
};

export default tamil;
